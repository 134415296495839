import React from 'react';
import Bg from '../assets/img/here_for_you_bg.png';
import ArrowDown from '../assets/img/arrow-down.svg';
import ImageCardContainer from '../components/ImageCardContainer';
import CardCareers from '../components/CardCareers';
import { RichText } from 'prismic-reactjs';
import CareersPlaceholder from '../components/CareersPlaceholder';
import Seo from '../components/SEO';
import { navigate } from 'gatsby';

const Careers = ({ pageContext }) => {
  if (!pageContext) {
    return '';
  }

  const pageLang = Object.keys(pageContext)[0];
  const pageData = Object.values(pageContext)[0];
  const homeTitle = Object.values(pageContext)[0]?.homeData?.page_name;

  const handleNavigate = () => {
    if (pageLang === 'en') {
      navigate('/');
      return;
    }
    navigate(`/${pageLang}`);
  };

  return (
    <div
      className="background-color-background"
      style={{
        overflowX: 'hidden',
      }}
    >
      <Seo
        title={pageData?.meta_title}
        description={pageData?.meta_description}
        image={pageData?.meta_image?.url}
        lang={pageLang}
      />
      <ImageCardContainer
        backgroundImage={Bg}
        className="careers-container"
        nav={homeTitle}
        navTitle={pageData?.page_name}
        title={pageData?.title}
        description={pageData?.subtitle}
        sign={pageData?.author}
        handleNavigate={handleNavigate}
      >
        <div className="careers-description-container">
          <div className="title">
            <RichText render={pageData?.content?.raw} />
          </div>
          <img src={ArrowDown} alt="" className="careers-arrow-down" />
        </div>
        {pageContext?.card_group?.length > 0 ? (
          <div className="careers-card-container">
            {pageContext?.card_group?.map((item, index) => (
              <CardCareers
                title={item.position}
                subtitle={item.section}
                area={item.location}
                link={item.link.url}
                buttonText={item.button_text}
                key={index}
              />
            ))}
          </div>
        ) : (
          <CareersPlaceholder text={pageData?.placeholder} />
        )}
      </ImageCardContainer>
    </div>
  );
};

export default Careers;
