import React from 'react';
import SvgCardCareers from '../assets/img/SvgCardCareers.svg';
import Arrow from '../assets/img/arrow-right-white.svg';
import CargeButton from './CargeButton';

const CardCareers = ({ title, subtitle, buttonText, link, area }) => {
  return (
    <div
      className="card-careers-container"
      style={{
        background: `url(${SvgCardCareers})`,
      }}
    >
      <div className="card-title color-text-dark">{title}</div>
      <div className="card-subtitle color-text-grey">{subtitle}</div>
      <div className="card-careers-bottom-container">
        <div className="card-subtitle color-text-grey">{area}</div>
        <CargeButton className="card-careers-button" link={link}>
          <div className="d-flex justify-content-center">
            <div className="color-white body card-careers-button-text">{buttonText}</div>
            <img src={Arrow} alt="" />
          </div>
        </CargeButton>
      </div>
    </div>
  );
};

export default CardCareers;
