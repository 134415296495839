import React from 'react';
import Corner from '../assets/img/no-positions-corner.svg';
import { RichText } from 'prismic-reactjs';

const CareersPlaceholder = ({ text }) => {
  return (
    <div className="no-positions-container">
      <img src={Corner} alt="" className="no-positions-corner-start" />
      <div className="no-positions-text subtitle">
        <RichText render={text?.raw} />
      </div>
      <img src={Corner} alt="" className="no-positions-corner-end" />
    </div>
  );
};

export default CareersPlaceholder;
